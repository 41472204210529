import React from 'react';
import { FiMonitor , FiActivity, FiNavigation } from "react-icons/fi";
import { FaMobile, FaAndroid, FaAws } from "react-icons/fa";
import { CgCode } from "react-icons/cg";

const Service = () => {
    return (
        <div className="rn-service-area rn-section-gapBottom">
            {/* Start Service Area  */}
            <div className="rn-service-area">
                <div className="container">
                    <div className="row">

                        {/* Start Single Service  */}
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="service wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                <div className="inner">
                                    <div className="icon">
                                        <FaMobile />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Mobile Apps Development</h4>
                                        <p>
                                            Developing mobile applications is not as simple as squeezing a lemon as there are several frameworks and each with specific characteristics. 
                                            Choosing the right framework can save you a lot of time and money as it can make your job a lot easier. In the course of my work experience 
                                            I have had to face this problem many times and I feel very confident of being able to determine which solution is best suited to the purpose. 
                                            I was able to organize architectures based on native frameworks (<strong>iOS</strong> and <strong>Android</strong>), but also hybrid frameworks (React Native, Cordova).
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Service  */}

                        {/* Start Single Service  */}
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="service wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                <div className="inner">
                                    <div className="icon">
                                        <CgCode />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Frontend Development</h4>
                                        <p>
                                            Front end web developers use three primary coding languages to code the website and web app designs created by web designers: HTML, CSS, JavaScript. 
                                            There are different types of JavaScript frameworks for different needs. Among the most popular there is certainly <strong>ReactJS</strong> which allows 
                                            you to organize a structure of reusable components and front-end libraries for future use. During my work experience I have had the opportunity to develop 
                                            static and dynamic websites, using for example the advantages and the structure of a framework like <strong>React</strong> with <strong>GatsbyJS</strong>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Service  */}

                        {/* Start Single Service  */}
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="service wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                <div className="inner">
                                    <div className="icon">
                                        <FaAws />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Backend Development</h4>
                                        <p>
                                        The role of the backend developer can be part of the life cycle for developing an application. Sometimes it may be necessary to have a behind-the-scenes system to 
                                        support a client application (both web and mobile). In the course of my work experience I have also developed backend systems using <strong>ExpressJS</strong>, <strong>Django</strong>, <strong>Spring</strong> and based 
                                        on the <strong>serverless</strong> approach. 
                                        I therefore had the opportunity to take advantage of the cloud of Amazon, Google and Microsoft by organizing backends based on microservices. 
                                        In particular, I found it very interesting to use Lambda functions that support different programming languages and that allow you to focus on your business logic because 
                                        security and scalability are managed by the various service provider platforms such as <strong>AWS</strong>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Service  */}

                    </div>
                </div>
            </div>
            {/* End Service Area  */}

        </div>
    )
}

export default Service;